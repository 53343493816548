<app-top-nav-bar></app-top-nav-bar>

<mat-card class="mat-card-1">
     <h1 class="head">About <span class="v">V</span>ulture Industries</h1>
    <p class="desc">
        <span class="v">V</span>ulture Industries is a dynamic technology firm at the forefront of innovation, dedicated to revolutionizing various
        industries through cutting-edge solutions. With a commitment to excellence and a focus on automation,
        efficiency, and security, we develop pioneering products that address the evolving needs of our clients.

        Our suite of products encompasses a diverse range of solutions tailored to enhance operations across different
        sectors. From Optima Auto, which transforms plant automation with seamless RFID integration and automated
        processes, to Crusher Optima, a versatile application facilitating sales management in dynamic field
        environments, our products are designed to optimize efficiency, streamline processes, and drive growth.

        At <span class="v">V</span>ulture Industries, we understand the importance of security in today's digital landscape. That's why we offer
        solutions like Optima Guard, which utilizes advanced image detection technology to detect and prevent theft
        within plant premises, providing businesses with peace of mind and enhanced security measures.

        In addition to industrial solutions, we cater to the needs of consumers with products like Home Automation,
        offering seamless control over home environments for enhanced comfort and convenience.

        Our commitment to innovation extends beyond individual products; it's ingrained in our company culture. We
        continuously push the boundaries of technology, exploring new possibilities and developing solutions that
        anticipate and address the challenges of tomorrow.

        With a relentless focus on customer satisfaction, we strive to deliver not just products, but comprehensive
        solutions that empower businesses and consumers alike. At <span class="v">V</span>ulture Industries, we're not just shaping the future of
        industries; we're shaping the future of technology itself.
    </p> 
</mat-card>

<!-- <app-about-team></app-about-team> -->