import { Component } from '@angular/core';

@Component({
  selector: 'app-career-status',
  templateUrl: './career-status.component.html',
  styleUrls: ['./career-status.component.scss']
})
export class CareerStatusComponent {

}
