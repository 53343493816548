import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingHomePageComponent } from './components/landing-home-page/landing-home-page.component';
import { ServicePageComponent } from './components/service-page/service-page.component';
import { ProductsPageComponent } from './components/product-pages/products-page/products-page.component';
import { AccountPageComponent } from './components/account-page/account-page.component';
import { AboutUsPageComponent } from './components/about-us/about-us-page/about-us-page.component';
import { CareerPageComponent } from './components/career/career-page/career-page.component';
import { ContactUsComponent } from './components/contact/contact-us/contact-us.component';

const routes: Routes = [
  { path: '', component: LandingHomePageComponent },
  { path: 'service-screen', component: ServicePageComponent },
  { path: 'products-screen', component: ProductsPageComponent },
  { path: 'contact-screen', component: ContactUsComponent },
  { path: 'careers-screen', component: CareerPageComponent },
  { path: 'about-screen', component: AboutUsPageComponent },
  { path: 'accounts-screen', component: AccountPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
