<app-top-nav-bar></app-top-nav-bar>

<mat-card class="mat-card-1">

    <div class="name">
        <h1>Our Product's </h1>
    </div>

    <div class="containt">

        <mat-card class="profile-one" id="cms-back">
            <span>CAS/CMS</span>
            <small style="font-size: 10px;" class="desc">Crusher Automation/Management System</small>
            <div class="buttons">
                <button mat-flat-button class="primary">View</button>
                <button mat-flat-button class="explore-button">Contact</button>
            </div>
        </mat-card>

        <mat-card class="profile-one" id="auto-optima">
            <span>Auto Optima</span>
            <small style="font-size: 10px;" class="desc">Automate Your Plant, No More Man Force</small>
            <div class="buttons">
                <button mat-flat-button class="primary">View</button>
                <button mat-flat-button class="explore-button">Contact</button>
            </div>
        </mat-card>

        <mat-card class="profile-one" id="optima-guard">
            <span>Optima Guard</span>
            <small style="font-size: 10px;" class="desc">Protect Your Plant, No More Theft Now</small>
            <div class="buttons">
                <button mat-flat-button class="primary">View</button>
                <button mat-flat-button class="explore-button">Contact</button>
            </div>
        </mat-card>

        <mat-card class="profile-one" id="bms">
            <span>BMS</span>
            <small style="font-size: 10px;" class="desc">Building Management Service</small>
            <div class="buttons">
                <button mat-flat-button class="primary">View</button>
                <button mat-flat-button class="explore-button">Contact</button>
            </div>
        </mat-card>

        <mat-card class="profile-one" id="home-automation">
            <span class="text">Home Automation</span>
            <small style="font-size: 10px;" class="desc">Automate Your Home, Feel Premium</small>
            <div class="buttons">
                <button mat-flat-button class="primary">View</button>
                <button mat-flat-button class="explore-button">Contact</button>
            </div>
        </mat-card>

    </div>
</mat-card>