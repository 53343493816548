import { Component } from '@angular/core';

@Component({
  selector: 'app-top-nav-bar',
  templateUrl: './top-nav-bar.component.html',
  styleUrls: ['./top-nav-bar.component.scss']
})
export class TopNavBarComponent {

  showChildOptions: boolean = false;

  toggleChildOptions() {
    this.showChildOptions = !this.showChildOptions;
  }
}
