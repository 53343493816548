import { Component } from '@angular/core';
import { Products } from 'src/app/modules/Products';

interface AvailableProducts {
  name: string;
  value: Products;
}

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent {

  isLoading = false;

  selectedProduct: Products[] = [];
  availableProducts: AvailableProducts[] = [
    { name: 'AUTO OPTIMA', value: Products.AUTO_OPTIMA },
    { name: 'BMS', value: Products.BMS },
    { name: 'CAS/CMS', value: Products.CAS_CMS },
    { name: 'HOME AUTOMATION', value: Products.HOME_AUTOMATION },
    { name: 'OPTIMA GUARD', value: Products.OPTIMA_GUARD }
  ];
}
