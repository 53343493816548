<mat-card class="mat-card-1">

    <div class="name">
        <h1>Meet Our Team's</h1>
    </div>

    <div class="containt">
        <mat-card class="profile-one customer-support">
            <span>Customer Support Team</span>
            <small style="font-size: 10px;">support@vultureindustries.com</small>
        </mat-card>
    
        <mat-card class="profile-one tech-support">
             <span>Tech Support team</span>
        </mat-card>
    
        <mat-card class="profile-one rAndD-support">
            <span>R & D Team</span>
        </mat-card>
    
        <mat-card class="profile-one software-tema">
            <span>SOFTWARE TEAM</span>
        </mat-card>

    </div>

</mat-card>