import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingHomePageComponent } from './components/landing-home-page/landing-home-page.component';
import { ServicePageComponent } from './components/service-page/service-page.component';
import { ProductsPageComponent } from './components/product-pages/products-page/products-page.component';
import { TopNavBarComponent } from './components/top-nav-bar/top-nav-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { AngularMaterialService } from './services/angular-materials.service';
import { ToastrModule } from 'ngx-toastr';
import { AccountPageComponent } from './components/account-page/account-page.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { FirstSectionComponent } from './components/home-page/first-section/first-section.component';
import { SecondSectionComponent } from './components/home-page/second-section/second-section.component';
import { ThirdSectionComponent } from './components/home-page/third-section/third-section.component';
import { FourthSectionComponent } from './components/home-page/fourth-section/fourth-section.component';
import { HammerModule } from '@angular/platform-browser';
import { IgxCarouselModule, IgxIconComponent } from 'igniteui-angular';
import { AboutUsPageComponent } from './components/about-us/about-us-page/about-us-page.component';
import { AboutTeamComponent } from './components/about-us/about-team/about-team.component';
import { AboutVisionComponent } from './components/about-us/about-vision/about-vision.component';
import { CareerPageComponent } from './components/career/career-page/career-page.component';
import { CareerStatusComponent } from './components/career/career-status/career-status.component';
import { ContactUsComponent } from './components/contact/contact-us/contact-us.component';
import { ContactPageComponent } from './components/contact/contact-page/contact-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    LandingHomePageComponent,
    ServicePageComponent,
    ProductsPageComponent,
    TopNavBarComponent,
    FooterComponent,
    ContactUsComponent,
    CareerPageComponent,
    AboutUsPageComponent,
    AccountPageComponent,
    FirstSectionComponent,
    SecondSectionComponent,
    ThirdSectionComponent,
    FourthSectionComponent,
    AboutTeamComponent,
    AboutVisionComponent,
    CareerStatusComponent,
    ContactPageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularMaterialService,
    ToastrModule.forRoot(),
    MatProgressBarModule,
    HammerModule,
    IgxCarouselModule,
    IgxIconComponent,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
  ],
  providers: [
    // {provide: FIREBASE_OPTIONS, useValue: environment.firebase}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
