<nav class="navbar navbar-expand-lg bg-white navbar-light p-3 shadow-sm fixed-top">

    <a class="navbar-brand" routerLink="/"><i class="fa-solid fa-shop me-2"></i>
        <strong><span class="logo-v">M</span>asix Smart</strong>
    </a>

    <div class="collapse navbar-collapse">
        <ul class="navbar-nav ms-auto ">
            <li><a class="nav-link mx-2 text-uppercase active" aria-current="page" routerLink="/">Home</a></li>
            <!-- <li><a class="nav-link mx-2 text-uppercase" routerLink="/about-screen">About</a></li> -->
            <!-- <li><a class="nav-link mx-2 text-uppercase" routerLink="/service-screen">Service</a></li> -->
            <li><a class="nav-link mx-2 text-uppercase" routerLink="/products-screen">Products</a></li>
            <li><a class="nav-link mx-2 text-uppercase" routerLink="/contact-screen">Contact Us</a></li>
            <li><a class="nav-link mx-2 text-uppercase" routerLink="/careers-screen">Career</a></li>
        </ul>
        <ul class="navbar-nav ms-auto flex-row">
            <li class="nav-item">
                <a class="nav-link mx-2 text-uppercase" href="https://crusheroptimamasterui.web.app/#/">
                    <button mat-stroked-button color="blue">
                        <mat-icon>login</mat-icon>Product Login
                    </button>
                </a>
            </li>

            <li class="nav-item">
                <a class="nav-link mx-2 text-uppercase" href="#">
                    <button mat-stroked-button color="accent">
                        <mat-icon>login</mat-icon>Account
                    </button>
                </a>
            </li>

            <li class="nav-item">
                <a class="nav-link mx-2 text-uppercase" href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=support@vultureindustries.com">
                    <button mat-mini-fab color="primary" aria-label="Drop Mail">
                        <mat-icon>mail</mat-icon>
                    </button>
                </a>
            </li>
        </ul>
    </div>
</nav>