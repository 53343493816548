<div class="container">

    <div class="loader-overlay" *ngIf="isLoading">
        <div class="loader-content">
            <mat-spinner diameter="50"></mat-spinner>
            <br>
            <h3>Please wait, sending your enquiry...</h3>
        </div>
    </div>

    <div class="froms">
        <form action="">
            <mat-form-field appearance="outline" class="col">
                <mat-label>Full Name</mat-label>
                <input matInput ngModel #input name="fullName" type="text" placeholder="Enter Your Full Name">
            </mat-form-field>

            <mat-form-field appearance="outline" class="col">
                <mat-label>Phone Number</mat-label>
                <input matInput ngModel #input name="phoneNumber" type="number" placeholder="Enter Your Phone Number">
            </mat-form-field>

            <mat-form-field appearance="outline" class="col">
                <mat-label>Email Address</mat-label>
                <input matInput ngModel #input name="emailAddress" type="email"
                    placeholder="Enter Your Email Address Here">
            </mat-form-field>

            <mat-form-field appearance="outline" class="col">
                <mat-label>Select Enquiry Products</mat-label>
                <mat-select placeholder="Select Enquiry Products" multiple [(ngModel)]="selectedProduct" name="modules">
                    <mat-option *ngFor="let prod of availableProducts" [value]="prod.value">{{ prod.name }}</mat-option>
                </mat-select>
            </mat-form-field>

            <button mat-flat-button color="primary" matTooltip="Submit Details" matTooltipPosition="below">
                <mat-icon>save</mat-icon>
                Submit Details!
            </button>
        </form>
    </div>
</div>