import { Component } from '@angular/core';

@Component({
  selector: 'app-landing-home-page',
  templateUrl: './landing-home-page.component.html',
  styleUrls: ['./landing-home-page.component.scss']
})
export class LandingHomePageComponent {

}
