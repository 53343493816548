import { Component } from '@angular/core';

@Component({
  selector: 'app-about-vision',
  templateUrl: './about-vision.component.html',
  styleUrls: ['./about-vision.component.scss']
})
export class AboutVisionComponent {

}
